import { createSlice, Dispatch } from '@reduxjs/toolkit';

import {
	ICreateSetupIntentRequest,
	IOpenBankingPaymentInitiationRequest,
	IOpenBankingPaymentInitiationResponse,
	IOpenBankingPaymentInitiationStatusResponse,
	IPaymentState,
	ISetupIntentResponse,
} from './payment.types';

// Create initial state
export const initialPaymentState: IPaymentState = {
	eventsInProgress: 0,
	cardPaymentVisibility: false,
	bankTransferDetailsVisibility: false,
	isPaymentComplete: false,
};

const paymentSlice = createSlice({
	name: 'payment',
	initialState: initialPaymentState,
	reducers: {
		RESET_PAYMENT_STATE() {
			return { ...initialPaymentState };
		},
		SET_PAYMENT_COMPLETE(state, action) {
			return {
				...state,
				isPaymentComplete: action.payload,
			};
		},
		CREATE_SETUP_INTENT(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress + 1,
			};
		},
		CREATE_SETUP_INTENT_SUCCESS(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		CREATE_SETUP_INTENT_FAIL(state) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		CREATE_ANONYMOUS_SETUP_INTENT(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress + 1,
			};
		},
		CREATE_ANONYMOUS_SETUP_INTENT_SUCCESS(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		CREATE_ANONYMOUS_SETUP_INTENT_FAIL(state) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		CREATE_OPEN_BANKING_PAYMENT_INITIATION(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress + 1,
			};
		},
		CREATE_OPEN_BANKING_PAYMENT_INITIATION_SUCCESS(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		CREATE_OPEN_BANKING_PAYMENT_INITIATION_FAIL(state) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		GET_OPEN_BANKING_PAYMENT_INITIATION_STATUS(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress + 1,
			};
		},
		GET_OPEN_BANKING_PAYMENT_INITIATION_STATUS_SUCCESS(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		GET_OPEN_BANKING_PAYMENT_INITIATION_STATUS_FAIL(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		SET_CARD_PAYMENT_VISIBILITY(state, action) {
			return {
				...state,
				cardPaymentVisibility: action.payload,
			};
		},
		SET_BANK_TRANSFER_DETAILS_VISIBILITY(state, action) {
			return {
				...state,
				bankTransferDetailsVisibility: action.payload,
			};
		},
	},
});

// Destructure and export the plain action creators
export const {
	RESET_PAYMENT_STATE,
	SET_PAYMENT_COMPLETE,
	CREATE_ANONYMOUS_SETUP_INTENT,
	CREATE_ANONYMOUS_SETUP_INTENT_SUCCESS,
	CREATE_ANONYMOUS_SETUP_INTENT_FAIL,
	CREATE_OPEN_BANKING_PAYMENT_INITIATION,
	CREATE_OPEN_BANKING_PAYMENT_INITIATION_SUCCESS,
	CREATE_OPEN_BANKING_PAYMENT_INITIATION_FAIL,
	CREATE_SETUP_INTENT,
	CREATE_SETUP_INTENT_FAIL,
	CREATE_SETUP_INTENT_SUCCESS,
	GET_OPEN_BANKING_PAYMENT_INITIATION_STATUS,
	SET_BANK_TRANSFER_DETAILS_VISIBILITY,
	SET_CARD_PAYMENT_VISIBILITY,
} = paymentSlice.actions;

/** Reset state */
export const resetPaymentState = () => async (dispatch: Dispatch) => {
	await dispatch(RESET_PAYMENT_STATE());
};

/** Set payment complete status */
export const setPaymentComplete =
	(visible: boolean) => async (dispatch: Dispatch) => {
		await dispatch(SET_PAYMENT_COMPLETE(visible));
	};

/** Create a stripe setup intent */
export const createSetupIntent =
	(setupIntent: ICreateSetupIntentRequest) => async (dispatch: Dispatch) => {
		const response = await dispatch(
			CREATE_SETUP_INTENT({
				request: {
					method: 'post',
					url: '1/payments/setup-intent',
					data: setupIntent,
				},
			}),
		);

		return response.payload?.data as ISetupIntentResponse;
	};

/** Create an anonymous Stripe setup intent */
export const createAnonymousSetupIntent = () => async (dispatch: Dispatch) => {
	const response = await dispatch(
		CREATE_ANONYMOUS_SETUP_INTENT({
			request: {
				method: 'get',
				url: '1/payments/setup-intent',
			},
		}),
	);

	return response.payload?.data as ISetupIntentResponse;
};

/** Create a Open Banking payment initiation */
export const createOpenBankingPaymentInitiation =
	(paymentInitiation: IOpenBankingPaymentInitiationRequest) => async (dispatch: Dispatch) => {
		const response = await dispatch(
			CREATE_OPEN_BANKING_PAYMENT_INITIATION({
				request: {
					method: 'post',
					url: '1/open-banking/payment-initiation',
					data: paymentInitiation,
				},
			}),
		);

		return response.payload?.data as IOpenBankingPaymentInitiationResponse;
	};

export const getOpenBankingPaymentInitiationStatus =
	(id: string) => async (dispatch: Dispatch) => {
		const response = await dispatch(
			GET_OPEN_BANKING_PAYMENT_INITIATION_STATUS({
				request: {
					method: 'get',
					url: `1/open-banking/payment-initiation/${id}/status`,
				},
			}),
		);

		return response.payload?.data as IOpenBankingPaymentInitiationStatusResponse;
	}

/** Show/hide card payment */
export const setCardPaymentVisibility =
	(visible: boolean) => async (dispatch: Dispatch) => {
		await dispatch(SET_CARD_PAYMENT_VISIBILITY(visible));
	};

export const setBankTransferDetailsVisibility =
	(visible: boolean) => async (dispatch: Dispatch) => {
		await dispatch(SET_BANK_TRANSFER_DETAILS_VISIBILITY(visible));
	};

export default paymentSlice.reducer;
